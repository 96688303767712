import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { LoadingProps, LoadState } from '../../components/LoadingStateUtil';
import useAuth from '../../auth/UseAuth';
import { FlexTableBox } from '../../components/FlexTableBox';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import {
  FilterParamsConfig,
  GetPipelineReview,
  PipelineReviewConfig,
  PipelineReviewRecord,
} from '../../data/SuperDuperFiestaData';
import {
  GridColDef,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridPagination,
  GridPreferencePanelParams,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  getGridStringOperators,
} from '@mui/x-data-grid-pro';
import PipelineReviewContext from '../../contexts/PipelineReviewContext';
import { FlexBox } from 'components/FlexBox';
import { renderCellBooleanCheck } from 'components/grid/GridCellBooleanCheck';
import { PipelineRulesModal } from './PipelineRulesModal/PipelineRulesModal';
import { WhitelistIssuesModal } from './WhitelistIssuesModal';

const ISSUE_COLUMNS = ['issues', 'pre_consolidation_issues', 'whitelisted_issues'] as const;
const ISSUE_FILTER_OPERATORS = [
  ...getGridStringOperators().filter(op => ['isEmpty', 'isNotEmpty'].includes(op.value)),
  {
    label: 'is true',
    value: 'isTrue',
    getApplyFilterFn: () => null,
  },
  {
    label: 'is false',
    value: 'isFalse',
    getApplyFilterFn: () => null,
  },
];

export const PipelineReviewGrid = ({ loadingProps }: { loadingProps: LoadingProps }) => {
  const { accessToken } = useAuth();

  const [, setLoadingState] = loadingProps;
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const { filterState, pipelineReviewParams, pipelineReviewState, resetPaginationModel, setResetPaginationModel } =
    useContext(PipelineReviewContext);

  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<ReadonlyArray<PipelineReviewRecord>>([]);
  const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });
  const [filterValues, setFilterValues] = React.useState<object[]>([]);
  const [sortValues, setSortValues] = React.useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([]);
  const [open, setOpen] = useState(false);

  const selectedRowIds = useMemo(
    () =>
      rowSelection
        .map(selectionId => rows.find(row => row.id === selectionId)?.row_id)
        .filter((id): id is string => id !== undefined),
    [rowSelection, rows]
  );

  const selectedRowIssues = useMemo(
    () =>
      rows
        .filter(row => rowSelection.includes(row.id))
        .map(row => {
          const issues = Array.isArray(row.issues) ? row.issues : [];
          const preConsolidationIssues = Array.isArray(row.pre_consolidation_issues)
            ? row.pre_consolidation_issues
            : [];

          const combinedIssues = Array.from(new Set([...issues, ...preConsolidationIssues]));

          return {
            rowId: row.row_id,
            issueIds: combinedIssues,
            pipelineId: row.pipeline_id,
          };
        }),
    [rows, rowSelection]
  );

  const canWhitelist = useMemo(
    () => pipelineReviewState.stage?.name.toLowerCase() === 'selection' && Boolean(pipelineReviewState.entity),
    [pipelineReviewState.stage, pipelineReviewState.entity]
  );

  const renderCellIssueNames = useCallback(
    (params: GridRenderCellParams) => {
      const issues = params.value;

      if (!issues || !Array.isArray(issues)) return '';

      return issues
        .map(issueId => {
          const issueName = filterState.issues.find(i => i.issueId === issueId)?.name;
          return issueName ? `${issueId} - ${issueName}` : '';
        })
        .filter(Boolean)
        .join(', ');
    },
    [filterState.issues]
  );

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      if (pipelineReviewParams !== null) {
        const queryParams: PipelineReviewConfig = { ...pipelineReviewParams };
        const filterParams: FilterParamsConfig = {};
        queryParams['pageNumber'] = (paginationModel.page + 1).toString();
        queryParams['pageSize'] = paginationModel.pageSize.toString();

        if (filterValues.length > 0) {
          filterValues.forEach(obj => {
            const key = Object.keys(obj)[0] as keyof typeof obj;
            filterParams[key] = obj[key];
          });

          queryParams['filterBy'] = filterParams;
        }
        if (sortValues.length > 0) {
          queryParams['sortBy'] = sortValues;
        }

        const data = await GetPipelineReview(queryParams, accessToken);

        if (data.length === 0) {
          setRowCount(0);
          setRows([]);
        } else {
          const disabledColumns = ['types'];
          const disabledProps = { filterable: false, sortable: false };

          // loop through keys of first record to find and set all headers
          let headers: GridColDef[] = [];
          Object.keys(data[0]).forEach(key => {
            if (key === 'needs_review') {
              return;
            }

            const headerProps: GridColDef = {
              field: key,
              headerName: humanize(key),
              width: 125,
            };

            if (typeof (data[0] as Record<string, unknown>)[key] === 'boolean') {
              headerProps.renderCell = renderCellBooleanCheck;
              headerProps.align = 'center';
              headerProps.filterOperators = ISSUE_FILTER_OPERATORS;
            }

            if (ISSUE_COLUMNS.includes(headerProps.field as (typeof ISSUE_COLUMNS)[number])) {
              headerProps.renderCell = renderCellIssueNames;
            }

            if (disabledColumns.includes(key)) {
              headers.push({ ...headerProps, ...disabledProps });
            } else {
              headers.push(headerProps);
            }
          });

          const hasIssuesHeader: GridColDef = {
            field: 'has_issues',
            headerName: 'Has Issues',
            width: 100,
            renderCell: renderCellBooleanCheck,
            align: 'center',
            filterOperators: ISSUE_FILTER_OPERATORS,
          };

          headers.push(hasIssuesHeader);

          setColumns(headers);
          setRowCount(data.length);
          setRows(
            data.map((row, index) => ({
              ...row,
              id: index,
              has_issues: Array.isArray(row.issues) && row.issues.length > 0,
            }))
          );
        }
      } else {
        setRowCount(0);
        setRows([]);
        setColumns([]);
      }
    });
  }, [
    setLoadingState,
    accessToken,
    pipelineReviewParams,
    paginationModel,
    filterValues,
    sortValues,
    renderCellIssueNames,
  ]);

  useEffect(() => {
    if (resetPaginationModel === true) {
      setPaginationModel({
        page: 0,
        pageSize: 100,
      });
      setResetPaginationModel(false);
    }
  }, [resetPaginationModel, setResetPaginationModel]);

  function humanize(str: string) {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function (m) {
        return m.toUpperCase();
      });
  }

  const handleEvent: GridEventListener<'preferencePanelClose'> = (params: GridPreferencePanelParams) => {
    if (params['openedPanelValue'] === 'filters') {
      let filterObjects: object[] = [];
      queryOptions.items.forEach(item =>
        filterObjects.push({ [`${item.field}_${item.operator}`]: item.value || true })
      );
      setFilterValues(filterObjects);
    }
  };

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    setQueryOptions({ ...filterModel });
  }, []);

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    setSortValues(sortModel.map(item => `${item.field}_${item.sort}`));
  }, []);

  function CustomFooter() {
    return (
      <FlexBox flexDirection={'row-reverse'}>
        <GridPagination />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </FlexBox>
    );
  }

  return (
    <FlexTableBox sx={{ gap: 1 }}>
      <FlexTableBox>
        <FlexBox flexDirection={'row-reverse'}>
          <PipelineRulesModal
            canOpen={rowSelection.length > 0 || filterValues.length > 0}
            open={open}
            setOpen={setOpen}
            allRows={null}
            selectedRowIds={selectedRowIds}
            selectedEntity={pipelineReviewState.entity}
            selectedPipeline={null}
            selectedStage={pipelineReviewState.stage}
            setRowSelection={setRowSelection}
          />
          <WhitelistIssuesModal
            selectedEntity={pipelineReviewState.entity}
            canWhitelist={canWhitelist}
            rowIssues={selectedRowIssues}
          />
        </FlexBox>
        <CompactGridWrapper
          columns={columns}
          rows={rows}
          rowHeight={30}
          pagination
          rowCount={rowCount < paginationModel.pageSize ? rowCount : Number.MAX_VALUE}
          paginationModel={paginationModel}
          pageSizeOptions={[100, 250, 500]}
          paginationMode='server'
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick
          checkboxSelection
          onRowSelectionModelChange={newSelection => {
            setRowSelection(newSelection);
          }}
          rowSelectionModel={rowSelection}
          filterMode='server'
          onFilterModelChange={onFilterChange}
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          filterDebounceMs={250}
          onPreferencePanelClose={handleEvent}
          slots={{
            footer: CustomFooter,
          }}
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [GridLogicOperator.And],
            },
            baseCheckbox: {
              size: 'small',
            },
          }}
        />
      </FlexTableBox>
    </FlexTableBox>
  );
};
