import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import useAuth from '../../auth/UseAuth';
import { Checkbox, createFilterOptions, TextField } from '@mui/material';
import { AutocompleteWrapper } from '../../components/AutocompleteWrapper';
import { FlexBox } from '../../components/FlexBox';
import PipelineReviewContext from '../../contexts/PipelineReviewContext';
import { ActionButton } from '../../components/DialogOpenButton';
import { GridColDef, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { FlexTableBox } from '../../components/FlexTableBox';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import {
  GetPipelineReviewIssues,
  GetPipelineReviewTypes,
  PipelineReviewConfig,
  SdfIssueType,
  SuperDuperFiestaPipelineReviewIssues,
  ContentFilterOption,
} from '../../data/SuperDuperFiestaData';
import { map } from 'lodash';
import { LoadState, LoadingProps } from '../../components/LoadingStateUtil';
import type { PipelineReviewActionType, IPipelineReviewAction } from '../../contexts/PipelineReviewContext';

const CONTENT_FILTER_OPTIONS: ContentFilterOption[] = [
  { displayName: 'Select All', name: 'select_all' },
  { displayName: 'Has Issues', name: 'has_issues' },
  { displayName: 'Has Whitelisted Issues', name: 'has_whitelisted_issues' },
];

export const PipelineReview = ({ loadingProps }: { loadingProps: LoadingProps }) => {
  const apiRef = useGridApiRef();
  const { accessToken } = useAuth();

  const {
    filterState,
    pipelineReviewState,
    pipelineReviewDispatch,
    pipelineReviewParams,
    clearContextSelection,
    setResetPaginationModel,
    getFilterData,
  } = useContext(PipelineReviewContext);

  const columns = useColumns();
  const [rows, setRows] = useState<ReadonlyArray<SuperDuperFiestaPipelineReviewIssues>>([]);
  const [contentTypes, setContentTypes] = useState<ReadonlyArray<number>>([]);
  const [, setLoadingState] = loadingProps;

  const typeParams: PipelineReviewConfig = useMemo(
    () => ({
      entity: pipelineReviewParams?.entity ?? '',
      stage: pipelineReviewParams?.stage ?? '',
    }),
    [pipelineReviewParams?.entity, pipelineReviewParams?.stage]
  );

  const issueParams: PipelineReviewConfig = useMemo(
    () => ({
      entity: pipelineReviewParams?.entity ?? '',
      stage: pipelineReviewParams?.stage ?? '',
      dataClassId: pipelineReviewParams?.dataClassId,
      dataSourceId: pipelineReviewParams?.dataSourceId,
      issueTypeIds: pipelineReviewParams?.issueTypeIds ?? [],
    }),
    [
      pipelineReviewParams?.entity,
      pipelineReviewParams?.stage,
      pipelineReviewParams?.dataClassId,
      pipelineReviewParams?.dataSourceId,
      pipelineReviewParams?.issueTypeIds,
    ]
  );

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      await getFilterData();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pipelineReviewState.stage === null && filterState.stages.length > 0) {
      const selectionStage = filterState.stages.find(s => s.name === 'selection');
      if (selectionStage) {
        pipelineReviewDispatch({ type: 'update_stage', stage: selectionStage });
      }
    }
  }, [filterState.stages, pipelineReviewState.stage, pipelineReviewDispatch]);

  useEffect(() => {
    if (pipelineReviewState.issueTypes === null && filterState.issueTypes.length > 0) {
      const reviewType = filterState.issueTypes.find(t => t.name === 'needs_review');
      if (reviewType) {
        pipelineReviewDispatch({ type: 'update_issueTypes', issueTypes: [reviewType] });
      }
    }
  }, [filterState.issueTypes, pipelineReviewState.issueTypes, pipelineReviewDispatch]);

  useEffect(() => {
    if (pipelineReviewState.contentFilter === null) {
      pipelineReviewDispatch({
        type: 'update_content_filter',
        contentFilter: CONTENT_FILTER_OPTIONS.filter(option => option.name === 'has_issues'),
      });
    }
  }, [pipelineReviewState.contentFilter, pipelineReviewDispatch]);

  // Only load types if entity and stage are set or changed
  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (typeParams.entity && typeParams.stage) {
        const typeData = await GetPipelineReviewTypes(typeParams, accessToken);
        setContentTypes(map(typeData, td => td.dataClassId));
      }
    });
  }, [accessToken, setLoadingState, typeParams]);

  // Only load issues if entity and stage are set or changed
  // Reload issues if entity, stage, dataClassId, dataSourceId, or issueTypeIds are set or changed
  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (issueParams.entity && issueParams.stage) {
        const countData = await GetPipelineReviewIssues(issueParams, accessToken);
        setRows(map(countData, (row, index) => ({ ...row, id: index })));
      } else {
        setRows([]);
      }
    });
  }, [accessToken, setLoadingState, issueParams]);

  const filterGridContent = useCallback(
    (rowSelected: GridRowSelectionModel) => {
      const rowId = rowSelected[0] as number | undefined;
      if (rowId !== undefined && rowId !== null) {
        const selectedRow = rows[rowId];
        pipelineReviewDispatch({ type: 'update_issueId', issueId: selectedRow.issueId });
      } else {
        pipelineReviewDispatch({ type: 'update_issueId', issueId: null });
      }
    },
    [rows, pipelineReviewDispatch]
  );

  const resetFilters = useCallback(() => {
    clearContextSelection();
    setResetPaginationModel(true);
  }, [clearContextSelection, setResetPaginationModel]);

  const handleFilterChange = useCallback(
    (type: PipelineReviewActionType, value: any) => {
      if (type === 'update_entity') {
        const resetState: IPipelineReviewAction = {
          type,
          entity: value,
          stage: filterState.stages.find(s => s.name === 'selection'),
          issueId: null,
          dataSource: null,
          dataClass: null,
        };
        pipelineReviewDispatch(resetState);
        setResetPaginationModel(true);
      } else {
        pipelineReviewDispatch({ type: 'update_issueId', issueId: null });
        pipelineReviewDispatch({ type, [type.replace('update_', '')]: value });
      }
    },
    [pipelineReviewDispatch, filterState.stages, setResetPaginationModel]
  );

  const getSelectedRowIndex = useMemo(() => {
    if (!pipelineReviewState.issueId) return [];
    const rowIndex = rows.findIndex(row => row.issueId === pipelineReviewState.issueId);
    return rowIndex >= 0 ? [rowIndex] : [];
  }, [rows, pipelineReviewState.issueId]);

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 1, width: '100%', height: '100%' }}>
      <AutocompleteWrapper
        fullWidth
        size='small'
        value={pipelineReviewState.entity || null}
        options={filterState.entities}
        getOptionLabel={entity => entity.displayName}
        renderInput={params => <TextField {...params} label={'Entity'} margin='dense' required />}
        onChange={(_, value) => handleFilterChange('update_entity', value)}
      />
      <AutocompleteWrapper
        fullWidth
        size='small'
        value={pipelineReviewState.stage || null}
        options={filterState.stages}
        getOptionLabel={stage => stage.displayName}
        renderInput={params => <TextField {...params} label={'Stage'} margin='dense' required />}
        onChange={(_, value) => handleFilterChange('update_stage', value)}
      />
      <AutocompleteWrapper
        fullWidth
        size='small'
        value={pipelineReviewState.dataClass || null}
        options={filterState.dataClasses.filter(dc => dc.dataClassId && contentTypes.includes(dc.dataClassId))}
        getOptionLabel={dataClass => dataClass.name}
        renderInput={params => <TextField {...params} label={'Type'} margin='dense' />}
        onChange={(_, value) => handleFilterChange('update_dataClass', value)}
      />
      <AutocompleteWrapper
        fullWidth
        size='small'
        value={pipelineReviewState.dataSource || null}
        options={filterState.dataSources}
        getOptionLabel={dataSource => dataSource.displayName}
        renderInput={params => <TextField {...params} label={'Source'} margin='dense' />}
        onChange={(_, value) => handleFilterChange('update_dataSource', value)}
      />
      <AutocompleteWrapper
        fullWidth
        multiple
        size='small'
        value={pipelineReviewState.issueTypes || []}
        options={filterState.issueTypes}
        getOptionLabel={issueType => issueType.displayName}
        renderInput={params => <TextField {...params} label={'IssueType'} margin='dense' />}
        filterOptions={(options, params) => {
          const filter = createFilterOptions<SdfIssueType>();
          const filtered = filter(options, params);
          return [{ issueTypeId: 0, displayName: 'Select All', name: 'Select All' }, ...filtered];
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              style={{ marginRight: 8 }}
              checked={
                option.displayName === 'Select All'
                  ? !!(filterState.issueTypes.length === (pipelineReviewState.issueTypes?.length ?? 0))
                  : selected
              }
            />
            {option.displayName}
          </li>
        )}
        onChange={(_, values) => {
          pipelineReviewDispatch({ type: 'update_issueId', issueId: null });
          if (values.find(option => option.name === 'Select All')) {
            return pipelineReviewDispatch({
              type: 'update_issueTypes',
              issueTypes: filterState.issueTypes,
            });
          }
          return pipelineReviewDispatch({ type: 'update_issueTypes', issueTypes: values });
        }}
      />
      <AutocompleteWrapper
        fullWidth
        multiple
        size='small'
        value={pipelineReviewState.contentFilter || []}
        options={CONTENT_FILTER_OPTIONS}
        getOptionLabel={option => option.displayName}
        renderInput={params => <TextField {...params} label={'Content'} margin='dense' />}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              style={{ marginRight: 8 }}
              checked={
                option.name === 'select_all'
                  ? !!(CONTENT_FILTER_OPTIONS.length === (pipelineReviewState.contentFilter?.length ?? 0))
                  : selected
              }
            />
            {option.displayName}
          </li>
        )}
        onChange={(_, values) => {
          pipelineReviewDispatch({ type: 'update_issueId', issueId: null });
          if (values.find(option => option.name === 'select_all')) {
            return pipelineReviewDispatch({
              type: 'update_content_filter',
              contentFilter: CONTENT_FILTER_OPTIONS.filter(option => option.name !== 'select_all'),
            });
          }
          return pipelineReviewDispatch({ type: 'update_content_filter', contentFilter: values });
        }}
      />
      <FlexTableBox sx={{ height: '100%' }}>
        <CompactGridWrapper
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          getRowHeight={() => 'auto'}
          disableMultipleRowSelection={true}
          onRowSelectionModelChange={rowId => {
            filterGridContent(rowId);
          }}
          rowSelectionModel={getSelectedRowIndex}
          columnsToAutoSize={columns.map(c => c.field)}
          initialState={{
            sorting: {
              sortModel: [{ field: 'issueCount', sort: 'desc' }],
            },
          }}
          sx={{
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: 'primary.light',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            },
          }}
          slots={{
            footer: () => (
              <div style={{ padding: '10px', borderTop: '1px solid #e0e0e0', textAlign: 'right' }}>
                Total Issues: {rows.reduce((sum, row) => sum + (row.issueCount || 0), 0).toLocaleString()}
              </div>
            ),
          }}
        />
      </FlexTableBox>
      <ActionButton
        sx={{ p: 0 }}
        title={'Clear Filters'}
        onClick={() => {
          resetFilters();
        }}
        disableMarginLeft={true}
      >
        <></>
      </ActionButton>
    </FlexBox>
  );
};

const useColumns = (): GridColDef[] => {
  return useMemo(() => {
    return [
      {
        field: 'issueName',
        headerName: 'Issues',
        width: 200,
      },
      {
        field: 'issueCount',
        headerName: '# of Failures',
        width: 100,
        align: 'right',
        valueFormatter: ({ value }) => value && Number(value?.toFixed(2)).toLocaleString(),
      },
    ];
  }, []);
};
