import React, { useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Alert } from '@mui/material';
import useAuth from 'auth/UseAuth';
import { useForm } from 'react-hook-form';
import { CancelButton } from 'components/CancelButton';
import { PrimaryButton } from 'components/PrimaryButton';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { WarningIndicator } from '../../components/WarningIndicator';
import { ErrorManagement, LoadingState } from '../../components/LoadingStateUtil';
import {
  SuperDuperFiestaEntity,
  WhitelistIssueConfig,
  WhitelistIssues,
  WhitelistIssueQueryParams,
} from '../../data/SuperDuperFiestaData';
import { FormTextField } from 'components/form/FormTextField';

const defaultFormValues = {
  description: '',
};

interface RowIssues {
  rowId: string;
  issueIds: number[];
  pipelineId: string;
}

interface WhitelistIssuesModalProps {
  selectedEntity: SuperDuperFiestaEntity | null;
  canWhitelist: boolean;
  rowIssues: RowIssues[];
}

export const WhitelistIssuesModal = ({ selectedEntity, canWhitelist, rowIssues }: WhitelistIssuesModalProps) => {
  const { accessToken } = useAuth();

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [open, setOpen] = useState<boolean>(false);

  const form = useForm({
    defaultValues: defaultFormValues,
    mode: 'onChange',
  });

  const { reset, control, watch, trigger } = form;

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleSave = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!selectedEntity) {
        throw new Error('No entity selected');
      }

      const description = watch('description');

      const whitelistConfig: WhitelistIssueConfig[] = rowIssues.flatMap(({ rowId, issueIds, pipelineId }) =>
        issueIds.map(issueId => ({
          rowId,
          issueId,
          pipelineId,
          description: description.trim(),
        }))
      );

      await WhitelistIssues(
        { entityId: selectedEntity.entityId } as WhitelistIssueQueryParams,
        whitelistConfig,
        accessToken
      );
      handleClose();
    });
  };

  const handleClose = () => {
    reset(defaultFormValues);
    setOpen(false);
    setLoadingState({ status: 'NotStarted' });
  };

  return (
    <>
      {canWhitelist && <DialogOpenButton title={'Whitelist'} onClick={handleOpen} disabled={rowIssues.length === 0} />}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          Whitelist Issues
        </DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ flexGrow: 1, mt: 1 }}>
            <Alert severity='info' sx={{ mb: 2 }}>
              Whitelist all issues for the selected rows
            </Alert>
            <FormTextField
              name='description'
              control={control}
              label='Enter a description for the whitelisting issues'
              trigger={trigger}
              additionalProps={{ multiline: true, rows: 6 }}
            />
          </Box>
        </DialogContent>
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <ErrorIndicator loadingState={loadingState} />
        <WarningIndicator loadingState={loadingState} />
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={loadingState.status === 'Loading'}>
            Cancel
          </CancelButton>
          <PrimaryButton onClick={handleSave} disabled={loadingState.status === 'Loading'}>
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
