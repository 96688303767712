import React, { createContext, useEffect, useReducer, useState, useContext } from 'react';
import {
  PipelineReviewConfig,
  SdfIssueType,
  SuperDuperFiestaDataClass,
  SuperDuperFiestaDataSource,
  SuperDuperFiestaEntity,
  SuperDuperFiestaStage,
  ContentFilterOption,
} from '../data/SuperDuperFiestaData';
import { useFilterState } from 'components/hooks/UseFilterState';
import { FilterState, FilterAction, initialFilterState } from 'components/hooks/UseFilterState';

interface IPipelineReviewState {
  entity: SuperDuperFiestaEntity | null;
  stage: SuperDuperFiestaStage | null;
  dataSource: SuperDuperFiestaDataSource | null;
  dataClass: SuperDuperFiestaDataClass | null;
  issueId: number | null;
  issueTypes: SdfIssueType[] | null;
  contentFilter: ContentFilterOption[] | null;
  pageNumber: string | null;
  pageSize: string | null;
}

export interface IPipelineReviewAction {
  type:
    | 'update_entity'
    | 'update_stage'
    | 'update_dataSource'
    | 'update_dataClass'
    | 'update_issueId'
    | 'update_issueTypes'
    | 'update_content_filter'
    | 'reset';
  entity?: SuperDuperFiestaEntity | null;
  stage?: SuperDuperFiestaStage | null;
  dataSource?: SuperDuperFiestaDataSource | null;
  dataClass?: SuperDuperFiestaDataClass | null;
  issueId?: number | null;
  issueTypes?: SdfIssueType[] | null;
  contentFilter?: ContentFilterOption[] | null;
  pageNumber?: string | null;
  pageSize?: string | null;
}

const initialState: IPipelineReviewState = {
  entity: null,
  stage: null,
  dataSource: null,
  dataClass: null,
  issueId: null,
  issueTypes: null,
  contentFilter: null,
  pageNumber: null,
  pageSize: null,
};

function pipelineReviewReducer(state: IPipelineReviewState, action: IPipelineReviewAction): IPipelineReviewState {
  const { type } = action;

  switch (type) {
    case 'update_entity': {
      const { type, ...newState } = action;
      return { ...state, ...newState };
    }
    case 'update_stage': {
      return { ...state, stage: action.stage ?? null };
    }
    case 'update_dataSource': {
      return { ...state, dataSource: action.dataSource ?? null };
    }
    case 'update_dataClass': {
      return { ...state, dataClass: action.dataClass ?? null };
    }
    case 'update_issueId': {
      return { ...state, issueId: action.issueId ?? null };
    }
    case 'update_issueTypes': {
      return { ...state, issueTypes: action.issueTypes ?? [] };
    }
    case 'update_content_filter': {
      return { ...state, contentFilter: action.contentFilter ?? [] };
    }
    case 'reset': {
      return { ...initialState };
    }
    default:
      return state;
  }
}

export interface IPipelineReviewContextData {
  pipelineReviewState: IPipelineReviewState;
  pipelineReviewParams: PipelineReviewConfig | null;
  resetPaginationModel: boolean;
  filterState: FilterState;
  getFilterData: () => void;
}

export interface IPipelineReviewContext extends IPipelineReviewContextData {
  pipelineReviewDispatch: React.Dispatch<IPipelineReviewAction>;
  clearContextSelection: () => void;
  setResetPaginationModel: React.Dispatch<React.SetStateAction<boolean>>;
  filterState: FilterState;
  filtersDispatch: React.Dispatch<FilterAction>;
  getFilterData: () => void;
}

export type PipelineReviewActionType = IPipelineReviewAction['type'];

const PipelineReviewContext = createContext<IPipelineReviewContext>({
  pipelineReviewParams: null,
  pipelineReviewState: initialState,
  pipelineReviewDispatch: () => {
    /* empty */
  },
  clearContextSelection: () => {
    /* empty */
  },
  resetPaginationModel: false,
  setResetPaginationModel: () => {
    /* empty */
  },
  filterState: initialFilterState,
  filtersDispatch: () => {
    /* empty */
  },
  getFilterData: () => {
    /* empty */
  },
});

export const PipelineReviewContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [pipelineReviewParams, setPipelineReviewParams] = useState<PipelineReviewConfig | null>(null);
  const [pipelineReviewState, pipelineReviewDispatch] = useReducer(pipelineReviewReducer, initialState);
  const [resetPaginationModel, setResetPaginationModel] = useState<boolean>(false);

  const { filterState, filtersDispatch, getFilterData } = useFilterState({
    includeEntities: true,
    includeStages: true,
    includeDataClasses: true,
    includeDataSources: true,
    includeIssueTypes: true,
    includeIssues: true,
  });

  const clearContextSelection = () => {
    pipelineReviewDispatch({ type: 'reset' });
  };

  useEffect(() => {
    const newParams =
      pipelineReviewState.entity === null
        ? null
        : {
            entity: pipelineReviewState.entity.name,
            stage: pipelineReviewState?.stage?.name || 'selection',
            issueTypeIds: pipelineReviewState.issueTypes?.map(t => t.issueTypeId) ?? [],
            ...(pipelineReviewState.dataSource && { dataSourceId: pipelineReviewState.dataSource.dataSourceId }),
            ...(pipelineReviewState.dataClass && { dataClassId: pipelineReviewState.dataClass.dataClassId }),
            ...(pipelineReviewState.issueId && { selectedIssueId: pipelineReviewState.issueId.toString() }),
            ...(pipelineReviewState.contentFilter && {
              contentFilter: pipelineReviewState.contentFilter.map(c => c.name),
            }),
          };

    setPipelineReviewParams(newParams);
  }, [pipelineReviewState]);

  return (
    <PipelineReviewContext.Provider
      value={{
        pipelineReviewState,
        pipelineReviewDispatch,
        pipelineReviewParams,
        clearContextSelection,
        resetPaginationModel,
        setResetPaginationModel,
        filterState,
        filtersDispatch,
        getFilterData,
      }}
    >
      {children}
    </PipelineReviewContext.Provider>
  );
};

export const usePipelineReviewContext = () => {
  const context = useContext(PipelineReviewContext);
  if (!context) {
    throw new Error('usePipelineReviewContext must be used within a PipelineReviewContextProvider');
  }
  return context;
};

export default PipelineReviewContext;
